import {
    SET_LOCALE,
    SET_USER_CREDENTIAL,
    SET_USER_PROFILE,
    SET_BUSINESSCARD_DATA,
    SET_REQUEST_SERVICE_WIZARD_DATA,
    SET_PLAN_DATA,
    SET_CONTINUE_PATH
} 
from './action-types';

export const setLocale = locale => ({
    type: SET_LOCALE,
    locale: locale
});

export const setUserCredential = userCredential => ({
    type: SET_USER_CREDENTIAL,
    userCredential: userCredential
});

export const setUserProfile = userProfile => ({
    type: SET_USER_PROFILE,
    userProfile: userProfile
});

export const setBusinessCardData = businessCardData => ({ 
    type: SET_BUSINESSCARD_DATA, 
    businessCardData: businessCardData 
});

export const setRequestServiceWizardData = requestServiceWizardData => ({
    type: SET_REQUEST_SERVICE_WIZARD_DATA,
    requestServiceWizardData: requestServiceWizardData
});

export const setPlanData = planData => ({
    type: SET_PLAN_DATA, 
    planData: planData
});

export const setContinuePath = continuePath => ({
    type: SET_CONTINUE_PATH,
    continuePath: continuePath
});