import { SET_BUSINESSCARD_DATA } from '@app/state/actions/action-types';

const INITIAL_STATE = {
    businessCardData: null,
};

const businessCardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_BUSINESSCARD_DATA: {
            return {
                ...state,
                businessCardData: action.businessCardData 
            };
        }
        default: return state;
    }
}

export default businessCardReducer;