import React from "react";
import { injectIntl } from "react-intl";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import CustomHeader from "@app/components/Header/CustomHeader";
import Parallax from "@app/components/Parallax/Parallax";
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import LandingParallax from "@app/views/LandingPages/Sections/LandingParallax";

import landingPageStyle from "@app/assets/jss/material-kit-pro-react/views/landingPageStyle";

import professionals from "@app/assets/img/bg/professionals-2560w.webp";

const LoadingPage = props => {
    const { classes } = props;
    const appName = props.intl.formatMessage({ id: "app.name" });
    const loading = props.intl.formatMessage({ id: "loading" });

    window.scrollTo(0, 0);
    
    return (
        <div>
            <CustomHeader
                brandName={appName}
            />
            <LandingParallax/>          
        </div>
    );
};

export default injectIntl(withStyles(landingPageStyle)(LoadingPage));