import React from 'react';
import { connect } from "react-redux";
import { IntlProvider, addLocaleData } from "react-intl";
import locale_en from 'react-intl/locale-data/en';
import locale_ga from 'react-intl/locale-data/ga';
import locale_pl from 'react-intl/locale-data/pl';

import messages_en from '@app/messages/messages_en.json';
import messages_ga from '@app/messages/messages_ga.json';
import messages_pl from '@app/messages/messages_pl.json';

addLocaleData([...locale_en, ...locale_ga, ...locale_pl]);
const messages = {
    'en': messages_en,
    'ga': messages_ga,
    'pl': messages_pl
};
const languageWithRegionCode = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
const defaultLocale = languageWithRegionCode.toLowerCase().split(/[-_]/)[0];

const ConnectedIntlProvider = props => {
    const locale = props.locale ? props.locale : defaultLocale;
    return (
        <IntlProvider key={locale} locale={locale} messages={messages[locale]} textComponent={React.Fragment}>
            {props.children}
        </IntlProvider>
    )
};

const mapStateToProps = state => {
    return {
        locale: state.i18n.locale
    }
}
const DynamicIntlProvider = connect(mapStateToProps)(ConnectedIntlProvider);
export default DynamicIntlProvider;