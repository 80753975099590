import { SET_CONTINUE_PATH } from '@app/state/actions/action-types';

const INITIAL_STATE = {
    continuePath: null
};

const continuePathReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CONTINUE_PATH: {
            return { 
                ...state, 
                continuePath: action.continuePath 
            };
        }
        default: return state;
    }
}

export default continuePathReducer;
