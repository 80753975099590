import { lazy } from 'react';
import * as paths from '@app/constants/paths';

// Admin Pages
const UsersContainer = lazy(() => {
    return Promise.all([
        import('@app/views/AdminPages/UsersContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
// Auth Pages
const SignInContainer = lazy(() => {
    return Promise.all([
            import('@app/views/SignInPage/SignInContainer'), new Promise(resolve => setTimeout(resolve, 500))
        ])
        .then(([moduleExports]) => moduleExports);
});
const SignUpContainer = lazy(() => {
    return Promise.all([
        import('@app/views/SignUp/SignUpContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const SignUpPlanContainer = lazy(() => {
    return Promise.all([
        import('@app/views/SignUp/SignUpPlanContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const UploadBusinessCardContainer = lazy(() => {
    return Promise.all([
        import('@app/views/SignUp/UploadBusinessCardContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const SignOutContainer = lazy(() => {
    return Promise.all([
        import('@app/views/SignOutPage/SignOutContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const OnboardingWizardContainer = lazy(() => {
    return Promise.all([
        import('@app/views/SignUp/OnboardingWizardContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const CalendarContainer = lazy(() => {
    return Promise.all([
        import('@app/views/CalendarPage/CalendarContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const DashboardContainer = lazy(() => {
    return Promise.all([
        import('@app/views/Dashboard/DashboardContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const ProfileContainer = lazy(() => {
    return Promise.all([
        import('@app/views/ProfilePage/ProfileContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
// Services
const RequestServiceWizardContainer = lazy(() => {
    return Promise.all([
        import('@app/views/RequestService/RequestServiceWizardContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
// Landing
const LandingPage = lazy(() => {
    return Promise.all([
        import('@app/views/LandingPages/LandingPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
// Pricing
const PricingPage = lazy(() => {
    return Promise.all([
        import('@app/views/PricingPages/PricingPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const AboutUsPage = lazy(() => {
    return Promise.all([
        import('@app/views/AboutUsPage/AboutUsPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const Beta = lazy(() => {
    return Promise.all([
        import('@app/views/Beta/Beta'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const Blog = lazy(() => {
    return Promise.all([
        import('@app/views/Blog/BlogIndex/BlogPostsPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const ContactUsContainer = lazy(() => {
    return Promise.all([
        import('@app/views/ContactUsPage/ContactUsContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const MediaContainer = lazy(() => {
    return Promise.all([
        import('@app/views/MediaPage/MediaContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const CookiePolicyContainer = lazy(() => {
    return Promise.all([
        import('@app/views/CookiePolicy/CookiePolicyContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});

const HowItWorksCustomerPage = lazy(() => {
    return Promise.all([
        import('@app/views/HowItWorks/HowItWorksCustomerPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const HowItWorksProfessionalPage = lazy(() => {
    return Promise.all([
        import('@app/views/HowItWorks/HowItWorksProfessionalPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const FAQCustomerPage = lazy(() => {
    return Promise.all([
        import('@app/views/FAQ/FAQCustomerPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const FAQProfessionalPage = lazy(() => {
    return Promise.all([
        import('@app/views/FAQ/FAQProfessionalPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});

const PrivacyPolicyContainer = lazy(() => {
    return Promise.all([
        import('@app/views/PrivacyPolicy/PrivacyPolicyContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const TermsOfUseContainer = lazy(() => {
    return Promise.all([
        import('@app/views/TermsConditions/TermsOfUseContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const TermsOfSaleContainer = lazy(() => {
    return Promise.all([
        import('@app/views/TermsConditions/TermsOfSaleContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
})

// Blog Posts
const BlogBitcoin = lazy(() => {
    return Promise.all([
        import('@app/views/Blog/BlogPosts/Bitcoin/BlogPostPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const BlogDomain = lazy(() => {
    return Promise.all([
        import('@app/views/Blog/BlogPosts/Domain/BlogPostPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const BlogReciTech = lazy(() => {
    return Promise.all([
        import('@app/views/Blog/BlogPosts/ReciTech/BlogPostPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const BlogTOVS = lazy(() => {
    return Promise.all([
        import('@app/views/Blog/BlogPosts/TradingOnlineVoucher/BlogPostPage'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const TOVSWizardContainer = lazy(() => {
    return Promise.all([
        import('@app/views/Helpers/TOVS/WizardContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});
const OPSCalcContainer = lazy(() => {
    return Promise.all([
        import('@app/views/Tools/OPSCalcContainer'), new Promise(resolve => setTimeout(resolve, 500))
    ])
        .then(([moduleExports]) => moduleExports);
});

//import StripeContainer from '@app/views/Stripe/StripeContainer';

const routesIndex = [
    // Admin Pages
    { path: paths.USERS, component: UsersContainer, isPrivate: true, role: 'Admin'},

    // Auth Pages
    { path: paths.SIGN_UP, component: SignUpContainer, isPrivate: false },
    { path: paths.SIGN_UP_USER + ':userType', component: SignUpContainer, isPrivate: false },
    { path: paths.SIGN_UP_PLAN + ':plan', component: SignUpPlanContainer, isPrivate: true },
    { path: paths.SIGN_UP_BUSINESSCARD, component: UploadBusinessCardContainer, isPrivate: false },
    { path: paths.SIGN_IN, component: SignInContainer, isPrivate: false },
    { path: paths.SIGN_OUT, component: SignOutContainer, isPrivate: true },
    { path: paths.ONBOARDING, component: OnboardingWizardContainer, isPrivate: true },
    { path: paths.CALENDAR, component: CalendarContainer, isPrivate: true },
    { path: paths.DASHBOARD, component: DashboardContainer, isPrivate: true },
    { path: paths.USER_PROFILE, component: ProfileContainer, isPrivate: true },
    { path: paths.PUBLIC_PROFILE + ':profilePath', component: ProfileContainer, isPrivate: false },
    // Beta
    { path: paths.BETA, component: Beta, isPrivate: false},
    // Blog
    { path: paths.BLOG, component: Blog, isPrivate: false},
    { path: paths.INSANE_IN_THE_DOMAIN_NAME, component: BlogDomain, isPrivate: false},
    { path: paths.MO_FADAS_MO_FADHBS, component: BlogDomain, isPrivate: false},
    { path: paths.SZALONY_W_NAZWIE_DOMENY, component: BlogDomain, isPrivate: false},
    // Blog Pages
    { path: paths.BLOG_BITCOIN, component: BlogBitcoin, isPrivate: false},
    { path: paths.BLOG_BITCOIN_GA, component: BlogBitcoin, isPrivate: false},
    { path: paths.BLOG_DOMAIN, component: BlogDomain, isPrivate: false},
    { path: paths.BLOG_DOMAIN_GA, component: BlogDomain, isPrivate: false},
    { path: paths.BLOG_DOMAIN_PL, component: BlogDomain, isPrivate: false},
    { path: paths.BLOG_RECI_TECH, component: BlogReciTech, isPrivate: false},
    { path: paths.BLOG_TOVS, component: BlogTOVS, isPrivate: false},
    // Functional Pages
    { path: paths.REQUEST_SERVICE, component: RequestServiceWizardContainer, isPrivate: true},
    // Footer Pages
    { path: paths.ABOUT_US, component: AboutUsPage, isPrivate: false},
    { path: paths.CONTACT_US, component: ContactUsContainer, isPrivate: false},
    { path: paths.MEDIA, component: MediaContainer, isPrivate: false},
    // HowItWorks
    { path: paths.HOW_IT_WORKS_CUSTOMER, component: HowItWorksCustomerPage, isPrivate: false},
    { path: paths.HOW_IT_WORKS_PROFESSIONAL, component: HowItWorksProfessionalPage, isPrivate: false},
    // FAQ
    { path: paths.FAQ_CUSTOMER, component: FAQCustomerPage, isPrivate: false},
    { path: paths.FAQ_PROFESSIONAL, component: FAQProfessionalPage, isPrivate: false},
    // Pricing Page
    { path: paths.PRICING, component: PricingPage, isPrivate: false},
    { path: paths.PRICING_CUSTOMER, component: PricingPage, isPrivate: false},
    { path: paths.PRICING_PROFESSIONAL, component: PricingPage, isPrivate: false},
    // Legal Pages
    { path: paths.COOKIE_POLICY, component: CookiePolicyContainer, isPrivate: false},
    { path: paths.PRIVACY_POLICY, component: PrivacyPolicyContainer, isPrivate: false},
    { path: paths.TERMS_OF_USE, component: TermsOfUseContainer, isPrivate: false},
    { path: paths.TERMS_OF_SALE, component: TermsOfSaleContainer, isPrivate: false},
    // TOOLS
    { path: paths.TOOL_O_P_S, component: OPSCalcContainer, isPrivate: false },
    // HELPERS
    { path: paths.HELPER_T_O_V_S, component: TOVSWizardContainer, isPrivate: false },
    { path: paths.HELPER_T_O_V_S_ABOUT, component: TOVSWizardContainer, isPrivate: false },
    { path: paths.HELPER_T_O_V_S_DESCRIBE, component: TOVSWizardContainer, isPrivate: false },
    { path: paths.HELPER_T_O_V_S_PROJECT, component: TOVSWizardContainer, isPrivate: false },
    { path: paths.HELPER_T_O_V_S_PLAN, component: TOVSWizardContainer, isPrivate: false },
    { path: paths.HELPER_T_O_V_S_INVEST, component: TOVSWizardContainer, isPrivate: false },
    { path: paths.HELPER_T_O_V_S_OUTCOMES, component: TOVSWizardContainer, isPrivate: false },
    { path: paths.HELPER_T_O_V_S_ELEMENTS, component: TOVSWizardContainer, isPrivate: false },
    { path: paths.HELPER_T_O_V_S_QUOTE, component: TOVSWizardContainer, isPrivate: false },
    { path: paths.HELPER_T_O_V_S_COSTS, component: TOVSWizardContainer, isPrivate: false },
    { path: paths.HELPER_T_O_V_S_GRANT, component: TOVSWizardContainer, isPrivate: false }

    //{ path: paths.STRIPE, component: StripeContainer, isPrivate: false}
];

const defaultRoute = { component: LandingPage, isPrivate: false };

export { routesIndex, defaultRoute };
