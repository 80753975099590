import {
    container,
    title,
    main,
    mainRaised,
    whiteColor,
    blackColor,
    grayColor
} from "@app/assets/jss/material-kit-pro-react";

const landingPageStyle = theme => ({
    container: {
        color: whiteColor,
        ...container,
        zIndex: "2"
    },
    title: {
        ...title,
        color: blackColor,
        display: "inline-block",
        position: "relative",
        minHeight: "32px",
        color: whiteColor,
        textDecoration: "none"
    },
    titleDark: {
        ...title,
        textAlign: "center",
        width: "100%"
    },
    subtitle: {
        fontSize: "1.313rem",
        maxWidth: "500px",
        margin: "10px auto 0"
    },
    main: {
        ...main,
        paddingTop: "59px"
    },
    secondary: {
        ...main,
        paddingTop: "0px"
    },
    mainRaised: {
        ...mainRaised,
        color: blackColor,
        padding: "15px"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block"
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right"
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    cardParallax: {
        marginTop: "0px"
    },
    cardBodyParallax: {
        "@media (max-width: 960px)": {
            height: "35vh"
        },
        height: "60vh",
        display: "flex",
        alignItems: "center"
    },
    cardTitleWhite: {
        "&, & a": {
            ...title,
            marginTop: ".625rem",
            marginBottom: "0",
            minHeight: "auto",
            color: "#fff !important"
        }
    },
    cardDescriptionWhite: {
        color: "rgba(255, 255, 255, 0.8)"
    },
    textCenter: {
      textAlign: "center"
    },
    phoneContainer: {
        "& img": {
            maxWidth: "320px"
        }
    }
});

export default landingPageStyle;
