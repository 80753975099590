import React from 'react';

import { isIE, isSafari } from 'react-device-detect';

import logoWEBP from '@app/assets/img/logos/local-pro-logo-red-150w.webp';
import logoJP2 from '@app/assets/img/logos/local-pro-logo-red-150w.jp2';

const Brand = props => {
    const isWebpSupported = !(isIE || isSafari);
    const logoAlt = props.logoAlt ? props.logoAlt : "LocalPro logo";
    const logo = isWebpSupported ? logoWEBP : logoJP2;
    return (
        <img src={logo} alt={logoAlt}/>
    );
}

export default Brand;
