import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomDropdown from "@app/components/CustomDropdown/CustomDropdown";
import * as paths from '@app/constants/paths';

import menuDropdownStyle from "@app/assets/jss/material-kit-pro-react/components/menuDropdownStyle.jsx";

class AdminMenu extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(link) {
        this.props.history.push(link.key);
    }

    render() {
        const { classes } = this.props;
        const dropdownList = [
            { key: paths.USERS, value: this.props.intl.formatMessage({ id: "users" }) }
        ];

        return (
            <CustomDropdown
                onClick={this.handleClick}
                buttonText={this.props.intl.formatMessage({ id: "admin" })}
                buttonProps={{
                    round: false,
                    block: true,
                    color: "transparent",
                    className: classes.dropdown
                }}
                hoverColor= "primary"                    
                dropPlacement="bottom"
                dropdownList={dropdownList} />
        )
    }
}


export default withRouter(injectIntl(withStyles(menuDropdownStyle)(AdminMenu)));