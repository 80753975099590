import React from "react";
import { connect } from "react-redux";
import { isIE, isSafari } from 'react-device-detect';
import { injectIntl } from "react-intl";
import MediaQuery from 'react-responsive';
// nodejs library that concatenates classes
// path constants
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Parallax from "@app/components/Parallax/Parallax";
// Sections
 
// styles
import landingParallaxStyle from "@app/assets/jss/material-kit-pro-react/views/landingPageStyle";
// images
// WEBP Landscape
import professionalsXSwebpAlt from "@app/assets/img/bg/professional-service-providers-400w.webp";
import professionalsSMwebpAlt from "@app/assets/img/bg/professional-service-providers-800w.webp";
import professionalsMDwebpAlt from "@app/assets/img/bg/professional-service-providers-1280w.webp";
import professionalsLGwebpAlt from "@app/assets/img/bg/professional-service-providers-1440w.webp";
import professionalsXLwebpAlt from "@app/assets/img/bg/professional-service-providers-1920w.webp";
import professionalsXXLwebpAlt from "@app/assets/img/bg/professional-service-providers-2560w.webp";
// WEBP Portrait
import professionalsPTXSwebpAlt from "@app/assets/img/bg/professional-service-providers-portrait-400w.webp";
import professionalsPTSMwebpAlt from "@app/assets/img/bg/professional-service-providers-portrait-800w.webp";
import professionalsPTMDwebpAlt from "@app/assets/img/bg/professional-service-providers-portrait-1280w.webp";
import professionalsPTLGwebpAlt from "@app/assets/img/bg/professional-service-providers-portrait-1440w.webp";
import professionalsPTXLwebpAlt from "@app/assets/img/bg/professional-service-providers-portrait-1920w.webp";
// JP2 Landscape
import professionalsXSjp2Alt from "@app/assets/img/bg/professional-service-providers-400w.jp2";
import professionalsSMjp2Alt from "@app/assets/img/bg/professional-service-providers-800w.jp2";
import professionalsMDjp2Alt from "@app/assets/img/bg/professional-service-providers-1280w.jp2";
import professionalsLGjp2Alt from "@app/assets/img/bg/professional-service-providers-1440w.jp2";
import professionalsXLjp2Alt from "@app/assets/img/bg/professional-service-providers-1920w.jp2";
import professionalsXXLjp2Alt from "@app/assets/img/bg/professional-service-providers-2560w.jp2";
// JP2 Portrait
import professionalsPTXSjp2Alt from "@app/assets/img/bg/professional-service-providers-portrait-400w.jp2";
import professionalsPTSMjp2Alt from "@app/assets/img/bg/professional-service-providers-portrait-800w.jp2";
import professionalsPTMDjp2Alt from "@app/assets/img/bg/professional-service-providers-portrait-1280w.jp2";
import professionalsPTLGjp2Alt from "@app/assets/img/bg/professional-service-providers-portrait-1440w.jp2";
import professionalsPTXLjp2Alt from "@app/assets/img/bg/professional-service-providers-portrait-1920w.jp2";

class LandingParallax extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }
    
    render() {
        const isWebpSupported = !(isIE || isSafari);
        return (
            <MediaQuery query="(max-device-width: 9999px)">
                <MediaQuery query="(orientation: landscape)">
                    <MediaQuery query="(max-device-width: 420px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsXSwebpAlt : professionalsXSjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 421px) and (max-device-width: 720px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsSMwebpAlt : professionalsSMjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 721px) and (max-device-width: 1280px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsMDwebpAlt : professionalsMDjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 1281px) and (max-device-width: 1440px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsLGwebpAlt : professionalsLGjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 1441px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsXLwebpAlt : professionalsXLjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 1921px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsXXLwebpAlt : professionalsXXLjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                </MediaQuery>
                <MediaQuery query="(orientation: portrait)">
                    <MediaQuery query="(max-device-width: 420px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsPTXSwebpAlt : professionalsPTXSjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 421px) and (max-device-width: 720px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsPTSMwebpAlt : professionalsPTSMjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 721px) and (max-device-width: 1280px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsPTMDwebpAlt : professionalsPTMDjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 1281px) and (max-device-width: 1440px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsPTLGwebpAlt : professionalsPTLGjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 1441px)">
                        <Parallax 
                            image={isWebpSupported ? professionalsPTXLwebpAlt : professionalsPTXLjp2Alt} 
                            filter="dark"
                            small={this.props.userProfile}>
                            {this.props.parallaxContent}
                        </Parallax>
                    </MediaQuery>
                </MediaQuery>
            </MediaQuery>
        );
    }
}

const mapStateToProps = state => {
    return {
        locale: state.i18n.locale,
        userProfile: state.authentication.userProfile
    }
}

export default connect(mapStateToProps)(injectIntl(withStyles(landingParallaxStyle)(LandingParallax)));