import { combineReducers } from 'redux';
import localeReducer from './localeReducer';
import authenticationReducer from './authenticationReducer';
import businessCardReducer from './businessCardReducer';
import requestServiceReducer from './requestServiceReducer';
import planReducer from './planReducer';
import continuePathReducer from './continuePathReducer';

const rootReducer = combineReducers({
    i18n: localeReducer,
    authentication: authenticationReducer,
    businessCard: businessCardReducer,
    requestservice: requestServiceReducer,
    plan: planReducer,
    continue: continuePathReducer
});

export default rootReducer;
