import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as paths from '@app/constants/paths';

const ConnectedPrivateRoute = ({ component: Component, isPrivate, role, userCredential, userProfile, ...rest }) => {    
    return (
        <Route {...rest} render={props => (
            !isPrivate || 
            (userCredential && (!role || role == userProfile.role))
                ? <Component {...props} />
                : <Redirect to={{ pathname: paths.SIGN_IN, state: { from: props.location } }} />
        )} />
    )
};

const mapStateToProps = state => {
    return {
        userCredential: state.authentication.userCredential,
        userProfile: state.authentication.userProfile
    }
}

const PrivateRoute = connect(mapStateToProps)(ConnectedPrivateRoute);
export default PrivateRoute;
