import { CUSTOMER, PROFESSIONAL } from '@app/constants/userTypes';

//ADMIN
export const USERS = "/users";

// AUTH
export const WIZARD = '/wizard/';
export const SIGN_UP = '/sign-up';
export const SIGN_UP_USER = SIGN_UP + '/user/';
export const SIGN_UP_CUSTOMER = SIGN_UP_USER + CUSTOMER;
export const SIGN_UP_PROFESSIONAL = SIGN_UP_USER + PROFESSIONAL;
export const SIGN_UP_PLAN = SIGN_UP + '/plan/';
export const SIGN_UP_BUSINESSCARD = SIGN_UP  + '/businesscard';
export const SIGN_IN = '/sign-in';
export const SIGN_OUT = '/sign-out';
export const ONBOARDING = "/onboarding";
export const CALENDAR = '/calendar';
export const DASHBOARD = '/dashboard';
export const USER_PROFILE = "/user-profile";
export const PUBLIC_PROFILE = "/public-profile/";
/*
export const PUBLIC_AT = "/@";
export const PUBLIC_P = "/p/";
export const PUBLIC_PROFILE_L = "/l/";
export const PUBLIC_PROFILE_C = "/c/";
export const PUBLIC_PROFILE_G = "/g/";
export const PUBLIC_PROFILE_S = "/s/";
export const PUBLIC_PROFILE_T = "/t/";
export const PUBLIC_PROFILE_LANDING = "/landing/";
export const PUBLIC_PROFILE_CONTACT = "/contact/";
export const PUBLIC_PROFILE_GALLERY = "/gallery/";
export const PUBLIC_PROFILE_SERVICES = "/services/";
export const PUBLIC_PROFILE_TEAM = "/team/";
*/
// LANDING
export const LANDING = '/';
export const LANDING_CUSTOMER = '/customer';
export const LANDING_PROFESSIONAL = '/professional';
export const LANDING_BEAUTICIAN = '/beautician';
export const LANDING_CLEANER = '/cleaner';
export const LANDING_ELECTRICIAN = '/electrician';
export const LANDING_ENGINEER = '/engineer';
export const LANDING_FITNESS = '/fitness';
export const LANDING_GRINDS = '/grinds';
export const LANDING_KITCHEN_FITTER = '/kitchen-fitter';
export const LANDING_MASSAGE = '/massage';
export const LANDING_MECHANIC = '/mechanic';
export const LANDING_PLUMBER = '/plumber';
// PRICING
export const PRICING = '/pricing';
export const PRICING_CUSTOMER = '/pricing-customer';
export const PRICING_PROFESSIONAL = '/pricing-professional';
// OTHER
export const SERVICES = '/services';
export const REVIEWS = '/reviews';
// USERS
export const REQUEST_SERVICE = '/request-service';
export const MY_REQUESTS = '/my-requests';
export const SEARCH_REQUESTS = '/search-requests';
export const MESSAGES = '/messages';
export const MY_JOBS = '/my-jobs';
export const MY_REVIEWS = '/my-reviews';
// FOOTER
export const ABOUT_US = '/about-us';
export const BLOG = '/blog';
export const CONTACT_US = '/contact-us';
export const MEDIA = '/media';
export const HOW_IT_WORKS_CUSTOMER = '/how-it-works-customer';
export const HOW_IT_WORKS_PROFESSIONAL = '/how-it-works-professional';
export const FAQ_CUSTOMER = '/faq-customer';
export const FAQ_PROFESSIONAL = '/faq-professional';
export const STRIPE = '/stripe';
export const COOKIE_POLICY = '/cookie-policy';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_OF_USE = '/terms-of-use';
export const TERMS_OF_SALE = '/terms-of-sale';
export const BETA = '/beta';
// BLOG POSTS
export const INSANE_IN_THE_DOMAIN_NAME = '/blog/insane-in-the-domain-name';
export const MO_FADAS_MO_FADHBS = '/blag/mo-fadas-mo-fadhbs';
export const SZALONY_W_NAZWIE_DOMENY = '/blog/szalony-w-nazwie-domeny';
export const BLOG_BITCOIN = '/blog/bitcoin';
export const BLOG_BITCOIN_GA = '/blog/bonngiotán';
export const BLOG_DOMAIN = '/blog/insane-in-the-domain-name';
export const BLOG_DOMAIN_GA = '/blog/mo-fadas-mo-fadhbs';
export const BLOG_DOMAIN_PL = '/blog/szalony-w-nazwie-domeny';
export const BLOG_RECI_TECH = '/blog/registered-electrical-contractors-ireland-technology-stack';
export const BLOG_TOVS = '/blog/trading-online-voucher-scheme-2020';
// HELPERS
export const TOOL_O_P_S = "/tool/online-presence-score";
export const HELPER_T_O_V_S = "/help/trading-online-voucher-scheme-2020";
export const HELPER_T_O_V_S_ABOUT = "/help/trading-online-voucher-scheme-2020/about-your-business";
export const HELPER_T_O_V_S_DESCRIBE = "/help/trading-online-voucher-scheme-2020/describe-your-business";
export const HELPER_T_O_V_S_PROJECT = "/help/trading-online-voucher-scheme-2020/describe-your-project";
export const HELPER_T_O_V_S_PLAN = "/help/trading-online-voucher-scheme-2020/develop-project-plan";
export const HELPER_T_O_V_S_INVEST = "/help/trading-online-voucher-scheme-2020/business-investment";
export const HELPER_T_O_V_S_OUTCOMES = "/help/trading-online-voucher-scheme-2020/projected-outcomes";
export const HELPER_T_O_V_S_ELEMENTS = "/help/trading-online-voucher-scheme-2020/project-elements";
export const HELPER_T_O_V_S_QUOTE = "/quote/trading-online-voucher-scheme-2020";
export const HELPER_T_O_V_S_COSTS = "/help/trading-online-voucher-scheme-2020/project-costs";
export const HELPER_T_O_V_S_GRANT = "/help/trading-online-voucher-scheme-2020/grant-history";
export const HELPER_T_O_V_S_FORM = "/help/trading-online-voucher-scheme-2020/application-form";