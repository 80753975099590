import React from "react";
// core components
import Header from "@app/components/Header/Header";
import HeaderLinks from "@app/components/Header/HeaderLinks";
import Brand from "@app/components/Brand/Brand";

import ErrorBoundary from "@app/components/Error/ErrorBoundary";
// nodejs library to set properties for components
import PropTypes from "prop-types";

const CustomHeader = props => {
  return (
    <Header
      brand={<Brand logoAlt={props.logoAlt}/>}
      color={props.headerColor}
      links={<HeaderLinks dropdownHoverColor="white" minimalLinks/>}
      fixed
      changeColorOnScroll={{
        height: 300,
        color: "white"
      }}
      name={props.brandName}
    />
  );
}

CustomHeader.defaultProps = {
  brandName: 'LocalPro',
  headerColor: 'transparent',
  minimalLinks: false
};

CustomHeader.propTypes = {
  logoAlt: PropTypes.string,
  brandName: PropTypes.string,
  headerColor: PropTypes.string,
  minimalLinks: PropTypes.bool
}

export default CustomHeader