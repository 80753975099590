/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as paths from '@app/constants/paths';
import { CUSTOMER, PROFESSIONAL } from '@app/constants/userTypes';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Button from "@app/components/CustomButtons/Button";

import headerLinksStyle from "@app/assets/jss/material-kit-pro-react/components/headerLinksStyle";
import LanguageSwitchButton from "../i18n/LanguageSwitchButton";
import AdminMenu from "./AdminMenu";

function ConnectedHeaderLinks(props) {
    const { classes } = props;

    if (props.userProfile) {
        return (
            <List className={classes.list + " " + classes.mlAuto}>
                <ListItem className={classes.listItem}>
                    <Button
                        to={paths.DASHBOARD}
                        className={'classes.navLinkActive'}
                        color="transparent"
                        component={Link}>
                        <FormattedMessage id="dashboard" />
                    </Button>
                </ListItem>              
                <ListItem className={classes.listItem}>
                    <Button
                        to={paths.CALENDAR}
                        className={'classes.navLinkActive'}
                        color="transparent"
                        component={Link}>
                        <FormattedMessage id="calendar" />
                    </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Button
                        to={paths.USER_PROFILE}
                        className={classes.navLink + props.location === paths.USER_PROFILE ? " classes.navLinkActive" : ""}
                        color="transparent"
                        component={Link}>
                        <FormattedMessage id="profile" />
                    </Button>
                </ListItem>                
                <ListItem className={classes.listItem}>
                    <Button
                        disabled
                        to={paths.MESSAGES}
                        className={classes.navLink + props.location === paths.MESSAGES ? " classes.navLinkActive" : ""}
                        color="transparent"
                        component={Link}>
                        <FormattedMessage id="messages" />
                    </Button>
                </ListItem>
                {props.userProfile.userType === CUSTOMER &&
                    <React.Fragment>                        
                        <ListItem className={classes.listItem}>
                            <Button
                                disabled
                                to={paths.MY_REQUESTS}
                                className={classes.navLink + props.location === paths.MY_REQUESTS ? " classes.navLinkActive" : ""}
                                color="transparent"
                                component={Link}>
                                <FormattedMessage id="my.requests" />
                            </Button>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Button
                                to={paths.REQUEST_SERVICE}
                                className={classes.navLink + props.location === paths.REQUEST_SERVICE ? " classes.navLinkActive" : ""}
                                color="transparent"
                                component={Link}>
                                <FormattedMessage id="new.request" />
                            </Button>
                        </ListItem>
                    </React.Fragment>
                }
                {props.userProfile.userType === PROFESSIONAL &&
                    <React.Fragment>
                        <ListItem className={classes.listItem}>
                            <Button
                                disabled
                                to={paths.MY_JOBS}
                                className={classes.navLink + props.location === paths.MY_JOBS ? " classes.navLinkActive" : ""}
                                color="transparent"
                                component={Link}>
                                <FormattedMessage id="my.jobs" />
                            </Button>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Button
                                disabled
                                to={paths.SEARCH_REQUESTS}
                                className={classes.navLink + props.location === paths.SEARCH_REQUESTS ? " classes.navLinkActive" : ""}
                                color="transparent"
                                component={Link}>
                                <FormattedMessage id="search.requests" />
                            </Button>
                        </ListItem>
                    </React.Fragment>
                }
                <ListItem className={classes.listItem}>
                    <Button
                        to={paths.SIGN_OUT}
                        className={classes.navLink + props.location === paths.SIGN_OUT ? " classes.navLinkActive" : ""}
                        color="transparent"
                        component={Link}>
                        <FormattedMessage id="log.out" />
                    </Button>
                </ListItem>
                {props.userProfile.role == 'Admin' &&
                    <ListItem className={classes.listItem}>
                        <AdminMenu />
                    </ListItem>
                }
                <ListItem className={classes.listItem}>
                    <LanguageSwitchButton />
                </ListItem>
            </List>
        )
    }
    else {
        return (
            <List className={classes.list + " " + classes.mlAuto}>
                {!props.minimalLinks && <ListItem className={classes.listItem}>
                    <Button
                        to={paths.HOW_IT_WORKS_PROFESSIONAL}
                        className={classes.navLink + props.location === paths.HOW_IT_WORKS_PROFESSIONAL ? " classes.navLinkActive" : ""}
                        color="transparent"
                        component={Link}>
                        <FormattedMessage id="how.it.works" />
                    </Button>
                </ListItem>}
                {!props.minimalLinks && <ListItem className={classes.listItem}>
                    <Button
                        to={paths.PRICING_PROFESSIONAL}
                        className={classes.navLink + props.location === paths.PRICING_PROFESSIONAL ? " classes.navLinkActive" : ""}
                        color="transparent"
                        component={Link}>
                        <FormattedMessage id="pricing" />
                    </Button>
                </ListItem>}
                {!props.minimalLinks && <ListItem className={classes.listItem}>
                    <Button
                        to={paths.ABOUT_US}
                        className={classes.navLink + props.location === paths.ABOUT_US ? " classes.navLinkActive" : ""}
                        color="transparent"
                        component={Link}>
                        <FormattedMessage id="about.us" />
                    </Button>
                </ListItem>}
                <ListItem className={classes.listItem}>
                    <Button
                        to={paths.SIGN_IN}
                        className={classes.navLink + props.location === paths.SIGN_IN ? " classes.navLinkActive" : ""}
                        color="transparent"
                        component={Link}>
                        <FormattedMessage id="log.in" />
                    </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Button
                        to={paths.SIGN_UP_PROFESSIONAL}
                        className={classes.navLink + props.location === paths.SIGN_UP_PROFESSIONAL ? " classes.navLinkActive" : ""}
                        color="transparent"
                        component={Link}>
                        <FormattedMessage id="sign.up" />
                    </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <LanguageSwitchButton />
                </ListItem>
            </List>
        );
    }
}

const mapStateToProps = state => {
    return {
        userProfile: state.authentication.userProfile
    }
}

export default connect(mapStateToProps)(injectIntl(withStyles(headerLinksStyle)(ConnectedHeaderLinks)));
