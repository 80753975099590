import { SET_PLAN_DATA } from '@app/state/actions/action-types';

const INITIAL_STATE = {
    planData: null,
};

const planReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PLAN_DATA: {
            return {
                ...state,
                planData: action.planData 
            };
        }
        default: return state;
    }
}

export default planReducer;